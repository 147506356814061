import React from 'react';
import ContentLoader from 'react-content-loader';

const LoginLoader = (props) => {
        return <>
            <ContentLoader viewBox="0 0 500 450" height={450} width={500}
                backgroundColor="#eddede"
                foregroundColor="#9c9c9c"
            >
                <rect x="16" y="17" rx="0" ry="0" width="500" height="250" />
                {/* <circle cx="35" cy="298" r="20" />
                <rect x="69" y="279" rx="2" ry="2" width="275" height="15" />
                <rect x="69" y="303" rx="2" ry="2" width="140" height="15" /> */}
                <rect x="16" y="279" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="303" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="330" rx="3" ry="3" width="360" height="10" />
                {/* <rect x="16" y="350" rx="3" ry="3" width="360" height="10" />
                <rect x="16" y="370" rx="3" ry="3" width="360" height="10" /> */}
            </ContentLoader>

        </>;
};

export default LoginLoader;
