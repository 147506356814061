import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import ScheduleTabs from "../";
import ScheduleTabs from "../../components/user/ScheduleTabs";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
// import records from 'data.json';


class Dashboard extends Component {

    serviceType(e) {
        if (e == 'Place an Order' || e == 'Make an Appointment') {
            this.setState({ checked: true });
        } else {
            this.setState({ checked: false });
        }
    }

    componentDidMount() {
        this.getBusinessDetail();
    }

    cancelSubscription = (stripe_plan, stripe_id) => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'cancel/subscripton', { 'stripe_plan': stripe_plan, 'stripe_id': stripe_id }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    toast.success(res.data.message);
                    self.getBusinessDetail();
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getBusinessDetail = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getUserBillingHistrory', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    externalData: true,
                    records: res.data.lists,
                    current: res.data.current,
                    allSubscription: res.data.allSubscription
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "id",
                text: "sr #",
                sortable: true,
                cell: (record, index) => {
                    return index + 1
                },
            },
            {
                key: "number",
                text: "Invoice #",
                sortable: true,
                cell: (record, index) => {
                    return record.number
                },
            },
            {
                key: "total",
                text: "Total amount",
                cell: (record, index) => {
                    return '$' + record.total / 100

                },
            },

            {
                key: "status",
                text: "Status"
            },

            // {
            //     key: "invoice_pdf",
            //     text: "invoice_pdf",
            //     cell: (record, index) => {
            //         return <a href={record.invoice_pdf} download >View</a>

            //     },
            // },
            {
                key: "created",
                text: "Created At",
                sortable: true,
                cell: (record, index) => {
                    return new Date(record.created * 1000).toLocaleString()

                },
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            button: {
                excel: false,
                print: false
            }
        }
        this.state = {
            records: [],
            current: {},
            allSubscription: [],

        }
    }

    render() {
        return (
            <main className="dashboard-page billing-history">
                {/* Header */}
                <DashboardNavbar />
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        <Col lg={3} md={3} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        <Col lg={9} md={9} id="page-content-wrapper">
                            <section className="dashboard-area security invoice">
                                <div className="y-page-link ">
                                    <h4>Current Subscription
                                        <Link className='btn-sm btn-info pull-right mr-2' to={'update/card/information'}>Update Information</Link>
                                    </h4>
                                    <div className="table-responsive">
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Interval</th>
                                                    <th>Amount</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Status</th>
                                                    <th>Cancel At</th>
                                                    <th>Canceled At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.current.plan && (
                                                        <tr>
                                                            <td>1</td>
                                                            <td>{this.state.current.plan.nickname}</td>
                                                            <td>{this.state.current.plan.interval_count} {this.state.current.plan.interval}</td>
                                                            <td>${this.state.current.plan.amount / 100}</td>
                                                            <td>{new Date(this.state.current.current_period_start * 1000).toLocaleString()}</td>
                                                            <td>{new Date(this.state.current.current_period_end * 1000).toLocaleString()}</td>
                                                            <td>{this.state.current.status == 'trialing' ? 'active' : this.state.current.status}</td>
                                                            {this.state.current.canceled_at != null ?
                                                                <>
                                                                    <td>{this.state.current.cancel_at && (new Date(this.state.current.cancel_at * 1000).toLocaleString())}</td>
                                                                    <td>{this.state.current.canceled_at && (new Date(this.state.current.canceled_at * 1000).toLocaleString())}</td>
                                                                    <td></td>
                                                                </>
                                                                : <>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td> <button onClick={() => {
                                                                        const confirmBox = window.confirm("Are you sure you want to cancel? You’ll still have access to Katika Website Dreamer until the end of this billing cycle month.")
                                                                        if (confirmBox === true) {
                                                                            this.cancelSubscription(this.state.current.plan.id, this.state.current.id)
                                                                        }
                                                                    }} className='btn btn-sm btn-danger'>Cancel</button>
                                                                    </td>
                                                                </>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="y-page-link mt-5 ">
                                    <h4>Subscription History </h4>
                                    <div className="table-responsive">
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Interval</th>
                                                    <th>Amount</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Status</th>
                                                    <th>Cancel At</th>
                                                    <th>Canceled At</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.allSubscription.map((item, i) => {
                                                        return (

                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{item.plan.nickname}</td>
                                                                <td>{item.plan.interval_count} {item.plan.interval}</td>
                                                                <td>${item.plan.amount / 100}</td>
                                                                <td>{new Date(item.current_period_start * 1000).toLocaleString()}</td>
                                                                <td>{new Date(item.current_period_end * 1000).toLocaleString()}</td>
                                                                <td>{item.status == 'trialing' ? 'active' : item.status}</td>
                                                                {item.canceled_at != null ?
                                                                    <>
                                                                        <td>{item.cancel_at && (new Date(item.cancel_at * 1000).toLocaleString())}</td>
                                                                        <td>{item.canceled_at && (new Date(item.canceled_at * 1000).toLocaleString())}</td>
                                                                    </>
                                                                    : <>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </>
                                                                }

                                                            </tr>
                                                        )
                                                    })

                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="y-page-link call-to-act mt-5 none">
                                    <h4>Invoice History</h4>
                                    <div className="call-action">
                                        <div className="call-action-form">
                                            <div className="table-responsive">
                                                <Row>
                                                    <Col lg={12} md={12}>
                                                        <ReactDatatable
                                                            config={this.config}
                                                            records={this.state.records}
                                                            columns={this.columns}
                                                            extraButtons={this.extraButtons}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                            <ToastContainer />
                        </Col>
                    </Row>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;